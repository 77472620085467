/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */
import React from "react"
import { Provider } from "react-redux"
import store from "./src/redux/store"

import Layout from "./src/components/Layout"
// import { initializeCheckout } from "./src/features/cart/cartSlice"

// Import tailwind/global for all pages
import "./src/styles/global.css"

// const addScript = url => {
//   const script = document.createElement("script")
//   script.src = url
//   script.async = true
//   document.body.appendChild(script)
// }

// export const onClientEntry = async () => {
//   if (typeof window !== "undefined") {
//     store.dispatch(initializeCheckout() as any)
//   }

//   window.onload = () => {
//     addScript("http://www.chop8live.com/js/215020.js")
//   }
// }

export const wrapRootElement = ({ element }) => {
  return <Provider store={store}>{element}</Provider>
}

export const wrapPageElement = ({ element, props }) => {
  return <Layout>{element}</Layout>
}
